// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { useEffect, useRef } from "react";
import { PlasmicHeroCenterContainer } from "./plasmic/forsite_data/PlasmicHeroCenterContainer";
import LottieGif from "./LottieGif";
import mouseDown from "./LottieGif/LottieFiles/mousedown.json";

function HeroCenterContainer_(props, ref) {
    const scrollIndicatorRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollIndicatorRef.current) {
                if (window.scrollY > 0) {
                    console.log('scrolling down');
                    scrollIndicatorRef.current.classList.add('fade-out');
                } else {
                    console.log('scrolling to top');
                    scrollIndicatorRef.current.classList.remove('fade-out');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
      <div style={{ position: 'relative', height: '100%' , width: '100%'}}>
  <PlasmicHeroCenterContainer root={{ ref }} {...props}

                              mousedown={
                                  <div ref={scrollIndicatorRef} className={"scroll-indicator-mouse"}>
                                  <LottieGif
                                  gifpath={mouseDown}
                              height={"60px"}
                              width={"100%"}
                              zIndex={100}/>
                                </div>
          }
  />
        {/*<div className="scroll-indicator" />*/}



      </div>
  )
}

const HeroCenterContainer = React.forwardRef(HeroCenterContainer_);

export default HeroCenterContainer;
