import React from 'react';
import { useState } from 'react';
import { Collapse } from 'antd';
import { ReactComponent as PlusIcon } from './plus.svg';

const FaqAccordion = (props) => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsSpinning(true);
        setIsClicked(!isClicked);
        // Automatically stop spinning after 1 second (duration of the animation)
        setTimeout(() => {
            setIsSpinning(false);
        }, 1000); // Match this with the duration of your CSS animation
    };

    return (
        <div style={{ width: "100%" }}>
            <Collapse
                size="large"
                bordered={false}
                expandIconPosition={"end"}
                ghost={true}
                style={{ width: "100%" }}
                onChange={handleClick}
                expandIcon={({ isActive }) => (
                    <PlusIcon
                        className={isSpinning ? 'spinning' : ''}
                        style={{ width: "30px",
                            height: "30px",
                            stroke: isClicked ? "#FFA500" : "#ffffff",
                    }}
                    />
                )}
            >
                <Collapse.Panel
                    key={props.uniqueKey}
                    header={
                        <p
                            className={`faq-label ${isClicked ? 'faq-label-clicked' : ''}`}
                            style={{ margin: 0 }}
                        >
                            {props.question}
                        </p>
                    }
                >
                    <p style={{ color: "#ffffff" }}>{props.answer}</p>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}

export default FaqAccordion;
