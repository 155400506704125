// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { useState } from "react";
import { PlasmicToplineNumber } from "./plasmic/forsite_data/PlasmicToplineNumber";

function ToplineNumber_(props, ref) {
  const [isHovered, setIsHovered] = useState(false);

  return (
      <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
      >
        <PlasmicToplineNumber
            root={{ ref }}
            {...props}
            bigNumber={React.cloneElement(props.bigNumber, { isHovered })}
        />
      </div>
  );
}

        const ToplineNumber = React.forwardRef(ToplineNumber_);

        export default ToplineNumber;
