// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {PlasmicHeroSection} from "./plasmic/forsite_data/PlasmicHeroSection";
import ParticleBackground2 from "./ParticleBackground2";
import LogoCarousel from "./LogoCarousel";
import AnimatedNumber from "./AnimatedNumber";
import ToplineNumber from "./ToplineNumber";
import LottieGif from "./LottieGif";
import swirl from "./LottieGif/LottieFiles/swirl2.json";
import circle from "./LottieGif/LottieFiles/circle.json";
import box from "./LottieGif/LottieFiles/box.json";


function useHeight() {
    const ref = useRef(null); // This ref is attached to the component for which height is needed
    const [height, setHeight] = useState(0); // State to store the height

    const set = () => { // Function to update height
        if (ref.current) {
            setHeight(ref.current.clientHeight);
        }
    };

    useEffect(() => {
        set(); // Set the initial height
        window.addEventListener('resize', set); // Adjust the height when window is resized

        return () => {
            window.removeEventListener('resize', set); // Clean up
        };
    }, []);

    return [ref, height]; // Return both the ref and the height
}

function HeroSection_(props, ref) {
    const [heroSectionRef, heroHeight] = useHeight(); // Get the ref and height from the hook


    return <PlasmicHeroSection hero={{ref: heroSectionRef}} {...props}
                               className={"hero-section=background"}

                               backgroundSection={
                                   <div>
                                       <ParticleBackground2 heroHeight={heroHeight}/>
                                   </div>
                               }

    />
}

const HeroSection = React.forwardRef(HeroSection_)

export default HeroSection;
