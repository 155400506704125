import * as React from "react";
import { PlasmicSolutionsSection } from "./plasmic/forsite_data/PlasmicSolutionsSection";
import LottieGif from "./LottieGif";
import block1 from "./LottieGif/LottieFiles/blocks1.json";
import block2 from "./LottieGif/LottieFiles/blocks2.json";
import block3 from "./LottieGif/LottieFiles/block3.json";
import block4 from "./LottieGif/LottieFiles/block4.json";
import block5 from "./LottieGif/LottieFiles/block5.json";
import block6 from "./LottieGif/LottieFiles/block6.json";
import FeaturesPanel from "./FeaturesPanel";

const textBlocks = {
    "expansionStrategy": {
        "title": "Strategic Data-Informed Expansion Planning",
        "description": "Our data-driven methodology pinpoints optimal locations for new venues or expansion by analyzing target audience demographics, behaviors, and exposure both locally and globally. This approach provides informed recommendations by considering customer preferences, market trends, surrounding businesses, and demand, providing a strategic advantage in selecting prime locations."
    },
    "competitorAnalysis": {
        "title": "Comprehensive AI-Powered Competitor Insights",
        "description": "By leveraging global business data, we offer both detailed and broad perspectives on your competitors, highlighting their strengths and weaknesses. Our insights, combined with sentiment analysis, help you strategically position your offerings, identify key focus areas, and make informed decisions; ensuring you stay ahead in the competitive landscape."
    },
    "marketingStrategy": {
        "title": "AI-Enhanced Strategic Marketing Planning",
        "description": "We provide you with a comprehensive understanding of the competitive landscape analyzing supply, competitor actions, and customer sentiment and behavior to provide a holistic view of current market strategies. These insights enable you to effectively reach your target audience, refine your marketing approach, and ultimately enhance business performance."
    },
    "conceptIdentification": {
        "title": "Concept Discovery through AI",
        "description": "Our data-driven approach enables the identification of concept-related topics to support cuisine validation and service recommendations, all benchmarked to market standards. By analyzing trends over time and saturation levels of similar concepts, we uncover unique opportunities and untapped cuisines or concepts, and ensure strategic decisions pinpoint opportunities for differentiation and growth in the market."
    },
    "audienceIdentification": {
        "title": "Precision Audience Targeting with AI",
        "description": "Our advanced algorithms sift through millions of data points to pinpoint your ideal customer profiles. By identifying key demographic and psychographic characteristics, we enable you to tailor your offerings and marketing strategies to the audiences most likely to engage."
    },
    "marketValidation": {
        "title": "Data-Driven Market Opportunity Assessment",
        "description": "Utilizing AI-driven analytics, we validate market opportunities by analyzing vast data sets to determine viability and potential success rates. Our approach ensures that your investments are grounded in data-driven confidence, minimizing risk while maximizing potential returns."
    }
}

function SolutionsSection_(props, ref) {
    const [currentSelected, setCurrentSelected] = React.useState("marketValidation");

    const handleMouseEnter = (key) => {
        setCurrentSelected(key);
    };

    console.log(currentSelected)

    return (
        <PlasmicSolutionsSection root={{ ref }} {...props}
                                 featuresColumn={
                                     <FeaturesPanel
                                         title={textBlocks[currentSelected].title}
                                         description={textBlocks[currentSelected].description}
                                         style={{
                                             paddingTop: "30px"
                                         }}
                                     />
                                 }

                                 marketValidation={{
                                     onMouseEnter: () => handleMouseEnter("marketValidation"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block1}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
                                 audienceIdentification={{
                                     onMouseEnter: () => handleMouseEnter("audienceIdentification"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block2}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
                                 conceptIdentification={{
                                        onMouseEnter: () => handleMouseEnter("conceptIdentification"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block3}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
                                 marketingStrategy={{
                                        onMouseEnter: () => handleMouseEnter("marketingStrategy"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block4}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
                                 competitorAnalysis={{
                                        onMouseEnter: () => handleMouseEnter("competitorAnalysis"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block5}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
                                 expansionStrategy={{
                                        onMouseEnter: () => handleMouseEnter("expansionStrategy"),
                                     imageSlot: (
                                         <LottieGif
                                             gifpath={block6}
                                             height={"100%"}
                                             width={"100%"}
                                         />
                                     )
                                 }}
        />
    );
}

const SolutionsSection = React.forwardRef(SolutionsSection_);

export default SolutionsSection;
