import React, { useEffect, useRef, useState, useMemo } from "react";
import { OrbitControls, useFBO } from "@react-three/drei";
import { Canvas, useFrame, extend, createPortal } from "@react-three/fiber";
import * as THREE from "three";
import { debounce } from 'lodash';

import SimulationMaterial from "./SimulationMaterial";

import vertexShader from "./vertexShader";
import fragmentShader from "./fragmentShader";
extend({ SimulationMaterial });

const FBOParticles = () => {
    const size = 80;
    // const points = useRef();
    const simulationMaterialRef = useRef();
    const particlesRef = useRef();

    const lastRenderTime = useRef(performance.now());
    const renderInterval = 1000 / 28;  // Target 28 FPS

    // const scene = new THREE.Scene();
    const scene = useMemo(() => new THREE.Scene(), []);
    // const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1);
    const camera = useMemo(() => new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1), []);

    const positions = new Float32Array([
        -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
    ]);
    const uvs = new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0]);

    const renderTarget = useFBO(size, size, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: false,
        type: THREE.FloatType,
    });

    // const particlesPosition = useMemo(() => {
    //     const length = size * size;
    //     const particles = new Float32Array(length * 3);
    //     for (let i = 0; i < length; i++) {
    //         let i3 = i * 3;
    //         particles[i3 + 0] = (i % size) / size;
    //         particles[i3 + 1] = i / size / size;
    //     }
    //     return particles;
    // }, [size]);
    const particlesPosition = useMemo(() => {
        const particles = new Float32Array(size * size * 2);
        for (let i = 0; i < size * size; i++) {
            particles[i * 3] = (i % size) / size;
            particles[i * 3 + 1] = i / size / size;
        }
        return particles;
    }, [size]);


    const uniforms = useMemo(
        () => ({
            uPositions: {
                value: null,
            },
        }),
        []
    );

    // useFrame((state) => {
    //     const { gl, clock } = state;
    //
    //     gl.setRenderTarget(renderTarget);
    //     gl.clear();
    //     gl.render(scene, camera);
    //     gl.setRenderTarget(null);
    //
    //     points.current.material.uniforms.uPositions.value = renderTarget.texture;
    //
    //     simulationMaterialRef.current.uniforms.uTime.value = clock.elapsedTime;
    // });
    useFrame(({ gl, clock }) => {
        const currentTime = performance.now();
        if (currentTime - lastRenderTime.current > renderInterval) {

            gl.setRenderTarget(renderTarget);
            gl.clear();
            gl.render(scene, camera);
            gl.setRenderTarget(null);

            if (particlesRef.current) {
                particlesRef.current.material.uniforms.uPositions.value = renderTarget.texture;
                simulationMaterialRef.current.uniforms.uTime.value = clock.elapsedTime;
            }
            lastRenderTime.current = currentTime;
        }
    });

    return (
        <>
            {createPortal(
                <mesh>
                    <simulationMaterial ref={simulationMaterialRef} args={[size]} />
                    <bufferGeometry>
                        <bufferAttribute
                            attach="attributes-position"
                            count={positions.length / 3}
                            array={positions}
                            itemSize={3}
                        />
                        <bufferAttribute
                            attach="attributes-uv"
                            count={uvs.length / 2}
                            array={uvs}
                            itemSize={2}
                        />
                    </bufferGeometry>
                </mesh>,
                scene
            )}
            <points ref={particlesRef}>
                <bufferGeometry>
                    <bufferAttribute
                        attach="attributes-position"
                        count={particlesPosition.length / 3}
                        array={particlesPosition}
                        itemSize={3}
                    />
                </bufferGeometry>
                <shaderMaterial
                    blending={THREE.AdditiveBlending}
                    depthWrite={false}
                    fragmentShader={fragmentShader}
                    vertexShader={vertexShader}
                    uniforms={uniforms}
                />
            </points>
        </>
    );
};

const ParticleBackground2 = ( {heroHeight}) => {
    const divRef = useRef(null);
    const [scrollValue, setScrollValue] = useState(0);
    const [initialHeight, setInitialHeight] = useState(600);

    // console.log(heroHeight)

    return (
        <div ref={divRef} style={{overflowY: 'auto', height: heroHeight, width: '100%'}}>
            <Canvas
                className="canvas"
                camera={{position: [1.5, 1.5, 2.5]}}
                style={{
                    background: "#171717",
                    width: `99vw`
                }}
            >
                <ambientLight intensity={0.5}/>
                <FBOParticles/>
                <OrbitControls enableZoom={false}/>
            </Canvas>
        </div>
    );
};

export default ParticleBackground2;
