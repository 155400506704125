import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "antd";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import styles from './styles.module.css'; // Importing the CSS file
import { useNavigate } from 'react-router-dom';

const linksList = { // Ensure the variable is structured correctly
    "pages": [
        {
            "title": "Home",
            "url": "/"
        },
        {
            "title": "Sign in",
            "url": "https://app.forsitedata.com/"
        },
        {
            "title": "Solutions",
            "url": "/#solutions-section"
        },
        {
            "title": "Case Studies",
            "url": "/#case-studies-section"
        },
        {
            "title": "FAQ",
            "url": "/#faq-section"
        },
        {
            "title": "Contact",
            "url": "/contact"
        },
        // Other pages...
    ],
    "social-media": [
        {
            "title": "Instagram",
            "url": "https://www.instagram.com/forsite_creative/?hl=en",
            "icon": faInstagram, // Correct FontAwesome icon usage
            "isExternal": true
        },
        {
            "title": "LinkedIn",
            "url": "https://www.linkedin.com/company/forsite-creative/",
            "icon": faLinkedin, // Correct FontAwesome icon usage
            "isExternal": true
        },
        // Other social media links...
    ]
}

const CloseButton = ({ onClose, isSpinning }) => {
    console.log(isSpinning)
    return (
        <button onClick={onClose} className={styles["close-button"]}>
            <FontAwesomeIcon icon={faTimes}
                             className={`${styles["close-icon"]} ${isSpinning ? styles.spinning : ''}`}
                             style={{ color: isSpinning ? '#FFA500' : '#FFFFFF' }} />
        </button>
    );
};

const DrawerBox = ({ onClose, open }) => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState('100%');
    const navigate = useNavigate(); // Use useNavigate hook for navigation

    useEffect(() => {
        const updateDrawerWidth = () => {
            if (window.innerWidth <= 768) {
                setDrawerWidth('80%');
            } else {
                setDrawerWidth('47%');
            }
        };

        window.addEventListener('resize', updateDrawerWidth);
        updateDrawerWidth(); // Initial check

        return () => window.removeEventListener('resize', updateDrawerWidth);
    }, []);

    const closeDrawer = () => {
        setIsSpinning(true);
        setTimeout(() => {
            setIsSpinning(false);
            onClose(); // Close the drawer here after spinner stops
        }, 400); // Adjust time as needed
    };

    const handleLinkClick = (link) => {
        closeDrawer(); // Always close the drawer
        if (!link.isExternal) {
            setTimeout(() => { // Ensure drawer close completes before navigating
                if (link.url.startsWith('/#')) { // Handle internal navigation with hash
                    const hash = link.url.split('#')[1];
                    navigate('/', { state: { scrollTo: hash } }); // Using navigate to redirect
                } else {
                    navigate(link.url);
                }
            }, 500);
        }
    };

    return (
        <Drawer
            placement="right"
            onClose={closeDrawer}
            width={drawerWidth}
            size="large"
            closeIcon={null}
            open={open}
            headerStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}
            drawerStyle={{ backgroundColor: '#151515' }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2rem' }}>
                <CloseButton onClose={closeDrawer} isSpinning={isSpinning} />
            </div>

            <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "-webkit-fill-available", maxHeight: "90%", justifyContent: "flex-start" }}>

                <div>
                    {linksList['pages'].map((link, index) => (
                        <a
                            key={index}
                            href={link.url}
                            className={styles["drawer-link"]}
                            target={link.isExternal ? '_blank' : '_self'}
                            rel={link.isExternal ? 'noopener noreferrer' : ''}
                            onClick={() => handleLinkClick(link)} // Adding click event handler
                        >
                            {link.title}
                        </a>
                    ))}
                </div>

                <div className={styles["social-media-icons"]} style={{ marginTop: 60 }}>
                    {linksList['social-media'].map((link, index) => (
                        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                            {link.icon && <FontAwesomeIcon icon={link.icon} className={styles["icon"]} />}
                        </a>
                    ))}
                </div>
            </div>
        </Drawer>
    );
};

export default DrawerBox;
