// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {PlasmicCaseStudiesSection} from "./plasmic/forsite_data/PlasmicCaseStudiesSection";
import SolutionItem from "./SolutionItem";

import FirstImage from "./images/CaseStudies/image (8).png";
import SecondImage from "./images/CaseStudies/image (9).png";
import ThirdImage from "./images/CaseStudies/image (10).png";
import FourthImage from "./images/CaseStudies/image (11).png";
import FifthImage from "./images/CaseStudies/image (12).png";
import SixthImage from "./images/CaseStudies/image (13).png";


const caseStudyList = [
    {
        'preface': 'Case Study 1.1',
        'shortTitle': 'Performance review of a collection of F&B brands',
        'title': 'Performance review of a collection of F&B brands operated by a renown restaurant group',
        'description': 'Forsite Data was able to curate data-centric insights to provide strategic guidance to a distinguished restaurant operator in Dubai. Our analysis deep dived into the market exposure of UAE residents and visitors, taking into account their spending behaviors and trends. This allowed us to further discern key F&B venues in UAE whereby their expenditures are maximized as well as understand the market sentiment towards these destinations.',
        'longDescription': 'Forsite Data utilizes the established set of restaurants to track visitor patterns, identifying other locations these visitors frequent. This analysis reveals market preferences and highlights any gaps in the market for specific cuisines or brands.',
        'image': FirstImage,
        'images': [FirstImage, SecondImage, ThirdImage]
    },
    {
        'preface': 'Case Study 1.2',
        'shortTitle': 'Target Audience analysis',
        'title': 'Target Audience analysis',
        'description': 'As part of a comprehensive market study for the renovation of a 5-star hotel brand in Jordan, Forsite Data has extracted insights about the target audience insights. This involved a detailed analysis of demographic factors such as age, gender and home countries, allowing for a comparison with the competitive landscape. The aim was to thus identify and capitalise on strategic F&B opportunities during the renovation.',
        'longDescription': 'Forsite data uses AI-powered text analysis to create an impact score. This score reveals which topics most influence review scores, helping you identify the key aspects of your venue worth investing in. Examples of these topics include both soft topics, which are easily adjustable based on market trends or feedback—such as service speed, staff friendliness, and menu variety—and hard topics, which focus on fixed components of a venue that are not easily changeable, such as lighting, entrance, and design.',
        'image': FourthImage,
        'images': [FourthImage, FifthImage, SixthImage]
    }

]


function CaseStudiesSection_(props, ref) {
    const [open, setOpen] = React.useState(false); // State to control dialog visibility
    const [selectedCaseStudy, setSelectedCaseStudy] = React.useState(null); // State to store selected case study

    const handleOpenDialog = (caseStudy) => {
        setSelectedCaseStudy(caseStudy);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <div>
            <PlasmicCaseStudiesSection root={{ ref }} {...props}
                                       caseStudyColumns={{
                                           children: caseStudyList.map((caseStudy, index) => (
                                               <SolutionItem
                                                   key={index}
                                                   preface={caseStudy.preface.toUpperCase()}
                                                   title={caseStudy.shortTitle}
                                                   description={caseStudy.description.slice(0, 200) + '...'}
                                                   image={caseStudy.image}
                                                   onClick={() => handleOpenDialog(caseStudy)} // Open dialog on click
                                               />
                                           ))
                                       }}
            />
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth="lg"
                PaperProps={{
                    style: {
                        backgroundColor: '#272727',
                        boxShadow: 'none',
                        maxHeight: '95vh',
                        overflowY: 'auto',
                        borderRadius: "16px"
                    }
                }}
            >
                <DialogContent sx={{p: 0, '::-webkit-scrollbar': { display: 'none' }, '-ms-overflow-style': 'none', 'scrollbar-width': 'none' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                        {/*<Box sx={{ display: 'flex', justifyContent: 'center', }}>*/}
                        {/*    <img src={selectedCaseStudy?.image} alt="Case Study" style={{ maxWidth: '100%', height: 'auto' }} />*/}
                        {/*</Box>*/}
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Carousel
                                autoPlay
                                infiniteLoop
                                interval={3200}
                                transitionTime={1000}
                                showThumbs={false}
                                showStatus={false}
                                useKeyboardArrows
                            >
                                {selectedCaseStudy?.images.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt={`Case Study ${index}`} style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                ))}
                            </Carousel>
                        </Box>
                        <Box sx={{ p: "24px", color: 'white'}}>
                            <Typography variant="h5" sx={{mt: 2, fontFamily: 'Inter' }}>{selectedCaseStudy?.title}</Typography>
                            <Typography sx={{mt: 2, fontFamily: 'Inter', fontWeight: 300 }}>{selectedCaseStudy?.longDescription}</Typography>
                            <Typography onClick={handleCloseDialog} style={{ cursor: 'pointer', color: 'orange', marginTop: "36px", fontSize: ".9em", fontFamily: 'Inter'  }}>Close window</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

const CaseStudiesSection = React.forwardRef(CaseStudiesSection_);

export default CaseStudiesSection;
