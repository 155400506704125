// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { PlasmicContactSubmissionButton } from "./plasmic/forsite_data/PlasmicContactSubmissionButton";
import LottieGif from "./LottieGif";
import sending from "./LottieGif/LottieFiles/sending.json";

function ContactSubmissionButton_(props, ref) {

  return <PlasmicContactSubmissionButton root={{ ref }} {...props}

                                         loadingDiv={
                                           <LottieGif
                                               gifpath={sending}
                                               height={"24px"}
                                               width={"100%"}
                                           />
                                         }


  />;
}

const ContactSubmissionButton = React.forwardRef(ContactSubmissionButton_);

export default ContactSubmissionButton;
