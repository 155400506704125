import React from "react";
import Slider from "react-slick";
import './LogoCarousel.css';

// Import the CSS files for slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IHG from '../images/logos/ihg.png';
import Fairmont from '../images/logos/fairmont.png';
import Marriott from '../images/logos/marriott.png';
import Mjr from '../images/logos/mjr.png';
import Rikas from '../images/logos/Rikas-Logo.png';
import Tristan from '../images/logos/tristan.png';
import W from '../images/logos/w.png';

function LogoCarousel() {
    // Settings for the slider
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 14000,
        autoplaySpeed: 0,
        // variableWidth: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <>
            <div className="slider-container">
                <Slider {...settings}>
                    <div className="logos-slide"><img src={IHG} alt="IHG"/></div>
                    <div className="logos-slide"><img src={Fairmont} alt="Fairmont"/></div>
                    <div className="logos-slide"><img src={Marriott} alt="Marriott"/></div>
                    <div className="logos-slide"><img src={Mjr} alt="Mjr"/></div>
                    <div className="logos-slide"><img src={Rikas} alt="Rikas"/></div>
                    <div className="logos-slide"><img src={Tristan} alt="Tristan"/></div>
                    <div className="logos-slide"><img src={W} alt="W"/></div>
                    <div className="logos-slide"><img src={IHG} alt="IHG"/></div>
                    <div className="logos-slide"><img src={Fairmont} alt="Fairmont"/></div>
                    <div className="logos-slide"><img src={Marriott} alt="Marriott"/></div>
                    <div className="logos-slide"><img src={Mjr} alt="Mjr"/></div>
                    <div className="logos-slide"><img src={Rikas} alt="Rikas"/></div>
                    <div className="logos-slide"><img src={Tristan} alt="Tristan"/></div>
                    <div className="logos-slide"><img src={W} alt="W"/></div>
                </Slider>
            </div>
        </>
    );
}

export default LogoCarousel;
