
import Homepage from './components/Homepage';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { useEffect } from 'react';
import LoaderScreen from './components/LoaderScreen';
import Contact from "./components/Contact";
import ScrollToHash from "./components/ScrollToComponent";

function App() {

  return (
      <Router>
            <ScrollToHash/>
          <LoaderScreen>
              <Routes>
                  <Route path="/" element={<Homepage/>}/>
                  <Route path="/contact" element={<Contact/>}/>
              </Routes>
          </LoaderScreen>
      </Router>
  );
}

export default App;
  