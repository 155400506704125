import React, { useState, useEffect, useRef } from 'react';

function AnimatedNumber({ finalCount, duration, showMillion, appended, metric, itemTitle, isHovered }) {
    const [count, setCount] = useState(0);
    const countRef = useRef(null); // Ref for the count element
    const intervalRef = useRef(null); // Ref for the interval to ensure we can clear it from anywhere

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                // Only start the counter when the component is visible
                const intervalTime = duration / finalCount; // Time between each increment

                intervalRef.current = setInterval(() => {
                    setCount((prevCount) => {
                        if (prevCount >= finalCount) {
                            return prevCount; // Return the current count without incrementing
                        }
                        return prevCount + 1;
                    });
                }, intervalTime);

                observer.disconnect(); // Disconnect observer after it has done its job
            }
        }, {
            threshold: 0.1 // Trigger when 10% of the element is visible
        });

        observer.observe(countRef.current); // Observe the ref element

        // Cleanup function
        return () => {
            clearInterval(intervalRef.current); // Clear the interval on component unmount or dependency change
            observer.disconnect(); // Clean up the observer
        };
    }, [finalCount, duration]); // Include finalCount and duration in the dependency array

    // Additional effect to handle stopping the count
    useEffect(() => {
        if (count >= finalCount) {
            clearInterval(intervalRef.current); // Clear the interval if the count reaches or exceeds final count
        }
    }, [count, finalCount]);

    return (
        <div ref={countRef} style={{cursor: 'default', display: 'flex', alignItems: 'center', flexDirection: 'column'}} className={"number-ref-parent"}>
            <div style={{display: 'flex', alignItems: 'center'}} className={"number-ref-child"}>
                <p className="animated-number"
                   style={{
                    color: isHovered ? "#FFA500" : "white",
                    fontWeight: 900,
                    lineHeight: 1.22,
                    margin: 0,
                    transition: "color 0.1s ease"
                }}>
                    {count}
                    {showMillion && <span>M</span>}
                </p>
                <p style={{
                    color: isHovered ? "#FFA500" : "white",
                    fontSize: "5rem",
                    fontWeight: 500,
                    lineHeight: 1,
                    margin: "0 0 0 10px", // Adjusted margin for spacing
                    transition: "color 0.1s ease"
                }}>{appended}</p>
            </div>
            <p style={{
                color: "white",
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: 1.22,
                margin: 0,
                transition: "color 0.1s ease"
            }}>
                {itemTitle}
            </p>
        </div>
    );
}

export default AnimatedNumber;
