// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {useEffect, useState} from 'react';
import {PlasmicCtaParent} from "./plasmic/forsite_data/PlasmicCtaParent";
import ContactOptionItem from "./ContactOptionItem";
import GovEntities from "./images/CTASection/govenrment entities.png";
import InvestmentOrg from "./images/CTASection/investment org.png";
import RetailBrands from "./images/CTASection/retail brands.png";
const Airtable = require('airtable');

const orgOptionsList = [
    {
        title: "For Government Entities",
        subtitle: "Ministries, agencies, SWFs, and other government entities.",
        value: "Government Entity",
        image: GovEntities
    },
    {
        title: "For Retail Brands & Property Management",
        subtitle: "Retail brands in F&B, hospitality,  property management, developers.",
        value: "Retail Brand & Property Management",
        image: RetailBrands
    },
    {
        title: "For Investment Organizations ",
        subtitle: "Investment firms, private equity, venture capital, and institutional investors.",
        value: "Investment Organization",
        image: InvestmentOrg
    }
]

function CtaParent_(props, ref) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [organizationType, setOrganizationType] = useState('');
    const [message, setMessage] = useState('')

    const [clicked, setClicked] = useState(false)
    const [loadingConnectButton, setLoadingConnectButton] = useState(false)

    const [ipData, setIpData] = useState('')


    const getIp = async () => {
        // Connect ipapi.co with fetch()
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        // Set the IP address to the constant `ip`
        setIpData(data)
    }
    // Run `getIP` function above just once when the page is rendered
    useEffect(() => {
        getIp()
    }, [])

    Airtable.configure({
        endpointUrl: 'https://api.airtable.com',
        apiKey: "patlWNzpo6ymtZuOu.595c1af5d72ea689618a0b57aa2695df3f6283ff2c2e0c71e43d61cadba38505"
    });
    var base = Airtable.base('appQErBmOGupfvr7M');


// send on form submit
    async function sendDataToAirTable() {
        setLoadingConnectButton(true)
        await base('web contact form').create([
            {
                "fields": {
                    'first name': firstName,
                    'last name': lastName,
                    'email': email,
                    'organization type': organizationType,
                    'message': message,
                    'time': new Date(Date.now()).toLocaleString(),
                    'ip': JSON.stringify(ipData),
                }
            },
        ], function (err, records) {
            if (err) {
                // console.error(err);
                return;
            }
            setLoadingConnectButton(false)
            setClicked(true)
            records.forEach(function (record) {
                // console.log(record.getId());
            });
        });

    }

    // Function to handle click and scroll
    const handleOptionClick = (value) => {
        setOrganizationType(value);
        // Attempt to find the contact-form and scroll into view
        setTimeout(() => {  // Timeout to ensure state updates and potential re-renders complete
            const formElement = document.querySelector('.contact-form');
            if (formElement) {
                // formElement.scrollIntoView({ behavior: 'smooth' });
                const yOffset = -50; // Offset to scroll up by 50 pixels
                const y = formElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }, 0);
    };

        return (
            <PlasmicCtaParent root={{ref}} {...props}

                                 orgOptionsSection={{
                                     children: orgOptionsList.map((option) => (
                                         <ContactOptionItem
                                             image={option.image}
                                             title={option.title}
                                             subtitle={option.subtitle}
                                             onClick={() => handleOptionClick(option.value)}
                                         />
                                     ))
                                 }}

                              email={{
                                  autoFocus: false,
                                  value: email,
                                  onChange: (e) => {
                                      setEmail(e.target.value);
                                  },
                              }}
                                firstName={{
                                    autoFocus: false,
                                    value: firstName,
                                    onChange: (e) => {
                                        setFirstName(e.target.value);
                                    },
                                }}
                                lastName={{
                                    autoFocus: false,
                                    value: lastName,
                                    onChange: (e) => {
                                        setLastName(e.target.value);
                                    },
                                }}
                                message={{
                                    autoFocus: false,
                                    value: message,
                                    onChange: (e) => {
                                        setMessage(e.target.value);
                                    },
                                }}
                              industry={{
                                    autoFocus: false,
                                    value: organizationType,
                                    onChange: (e) => {
                                        setOrganizationType(e.target.value);
                                    },

                              }}
                              connectButton={{
                                  loading: loadingConnectButton,
                                  onClick: () => {
                                      sendDataToAirTable();

                                  },
                                  clicked: clicked,
                              }}
        />
    )
}

    const CtaParent = React.forwardRef(CtaParent_);

    export default CtaParent;
