// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {useRef, useState, useEffect} from 'react';
import { PlasmicNavBarCustom } from "./plasmic/forsite_data/PlasmicNavBarCustom";
import DrawerBox from "./DrawerBox";

function NavBarCustom_(props, ref) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
      <div style={{width:"100%"}}>
        <DrawerBox onClose={onClose} open={open}/>
        <PlasmicNavBarCustom root={{ref}} {...props}
                             menu={{
                               onClick: showDrawer
                             }}
        />
      </div>

  )
}

const NavBarCustom = React.forwardRef(NavBarCustom_);

export default NavBarCustom;
