// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {useState, useEffect} from "react";
import {PlasmicHomepage} from "./plasmic/forsite_data/PlasmicHomepage";
import ToplineNumber from "./ToplineNumber";
import AnimatedNumber from "./AnimatedNumber";
import FaqAccordion from "./FaqAccordion";
import LogoCarousel from "./LogoCarousel";
import LottieGif from "./LottieGif";
import swirl from "./LottieGif/LottieFiles/swirl2.json";
import circle from "./LottieGif/LottieFiles/circle.json";
import box from "./LottieGif/LottieFiles/box.json";


const toplineMetrics = [
    {
        'text': 'Locations',
        'metric': 'million',
        'showMillion': true,
        'appended': '+',
        'value': 10,
        'duration': 700
    },
    {
        'text': 'Continents Covered',
        'metric': '',
        'showMillion': false,
        'appended': '',
        'value': 6,
        'duration': 800
    },
    {
        'text': 'Sentiments',
        'metric': 'million',
        'showMillion': true,
        'appended': '+',
        'value': 40,
        'duration': 900
    },
    {
        'text': 'Custom Personas',
        'metric': '',
        'showMillion': false,
        'appended': '+',
        'value': 30,
        'duration': 1000
    },
]

const faqQuestions = [
    {
        'question': 'What is Forsite Data?',
        'answer': 'Forsite Data is a leading big data consultancy specializing in leveraging advanced data analytics and AI technologies to drive strategic decision-making in the F&B and hospitality sectors. We harness the power of big data ethically and responsibly to deliver actionable insights and empower businesses to thrive in competitive markets.'
    },
    {
        'question': 'How does Forsite Data gather and analyze data?',
        'answer': 'Our company employs a combination of proprietary algorithms, AI, and machine learning techniques to collect and analyze data. We source information from a variety of ethical and sustainable channels, including public data sets, partnerships with data providers, and direct consumer interactions, always ensuring the highest standards of data integrity and relevance.'
    },
    {
        'question': 'What distinguishes Forsite Data from its competitors?',
        'answer': "What sets Forsite Data apart is our commitment to ethical data practices and our focus on customized, client-specific solutions. Unlike our competitors, we prioritize sustainable data sourcing and tailor our analytics to the specific needs of each client, ensuring that our insights are not only accurate but also directly applicable to our clients\' business challenges."
    },
    // {
    //     'question': 'How does Forsite Data ensure the security and privacy of its data?',
    //     'answer': 'Forsite Data implements stringent security measures and adheres to the highest standards of data privacy. We use state-of-the-art encryption, secure data storage solutions, and rigorous access controls to protect the data we handle. Regular audits and compliance checks ensure that our security practices meet or exceed industry standards.'
    // },
    {
        'question': 'Are Forsite Data\'s analytics services compliant with regulations like GDPR?',
        'answer': 'Yes, our data analytics services are fully compliant with the General Data Protection Regulation (GDPR) and other relevant regulations. We maintain a proactive approach to compliance, regularly updating our protocols and systems to align with legislative changes and best practices in data protection.'
    },
    {
        'question': 'Does Forsite Data offer real-time data analytics capabilities?',
        'answer': 'Absolutely. Forsite Data provides real-time analytics capabilities, enabling businesses to receive immediate insights into market trends, consumer behavior, and operational efficiencies. This is crucial for clients requiring timely data to make swift, informed decisions.'
    },
    {
        'question': 'Can businesses access their data analytics insights remotely or through mobile applications?',
        'answer': 'Yes, clients can access their analytics insights remotely. Forsite Data offers a versatile platform that includes mobile application support, allowing decision-makers to view and interact with their data insights on-the-go, from any location, ensuring that critical information is always at their fingertips.'
    },

]

function Homepage_(props, ref) {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    // listen for if a feature-card is clicked and if it is, then scroll to the feature-panel
    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (window.innerWidth < 768) {  // Check if the viewport width is indicative of a mobile device
                let element = event.target;
                while (element) {
                    if (element.id === 'feature-card') {
                        const targetElement = document.getElementById('feature-panel');
                        if (targetElement) {
                            const yOffset = -50; // Offset to scroll up by 50 pixels
                            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            window.scrollTo({top: y, behavior: 'smooth'});
                        }
                        break; // Stop the loop if we find the element and perform the scroll
                    }
                    element = element.parentNode;
                }
            }
        };

        // Attach the event listener to the document
        document.addEventListener('click', handleDocumentClick);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div>
            <PlasmicHomepage root={{ref}} {...props}


                             carouselSection={
                                 <>
                                     <LogoCarousel/>
                                 </>
                             }

                             values1={{
                                 imageSlot: <LottieGif
                                     gifpath={swirl}
                                     height={"180px"}
                                     width={"100%"}
                                 />
                             }}
                             values2={{
                                 imageSlot: <LottieGif
                                     gifpath={circle}
                                     height={"200px"}
                                     width={"100%"}
                                 />
                             }}
                             values3={{
                                 imageSlot: <LottieGif
                                     gifpath={box}
                                     height={"200px"}
                                     width={"100%"}
                                 />
                             }}


                             numbersContainer={{
                                 children: toplineMetrics.map((item, index) => (
                                     <div
                                         key={index}
                                         onMouseEnter={() => setHoveredIndex(index)}
                                         onMouseLeave={() => setHoveredIndex(null)}
                                     >
                                     <AnimatedNumber finalCount={item['value']}
                                                     duration={item['duration']}
                                                     showMillion={item['showMillion']}
                                                     appended={item['appended']}
                                                     metric={item['metric']}
                                                     itemTitle={item['text']}
                                                     isHovered={hoveredIndex === index}
                                     />
                                        </div>
                                 ))
                             }}

                             faqAccordion={{
                                 children: faqQuestions.map((question, index) => (
                                     <FaqAccordion
                                         key={index}
                                         question={question.question}
                                         answer={question.answer}
                                     />
                                 ))
                             }}
            />
        </div>
    )
}

const Homepage = React.forwardRef(Homepage_);

export default Homepage;
