import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToHash() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                // element.scrollIntoView();
                const yOffset = -80; // Offset by 80 pixels
                const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                console.log(yPosition);
                window.scrollTo({ top: yPosition, behavior: 'smooth' });
            }
        }
    }, [location]); // Re-run this effect if location changes

    return null;
}

export default ScrollToHash;