import React, { useState, useEffect } from 'react';
// import Loading from './images/loading.gif';
import LottieGif from "./LottieGif";
import circle from "./LottieGif/LottieFiles/swirl_lines_orange.json";

function LoadingGif() {
    return (
        <div style={{display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#171717'}}>
            {/*<img src={Loading} style={{height: '80px'}} alt="Loading..." />*/}
            <LottieGif
                gifpath={circle}
                height={"200px"}
                width={"100%"}
            />
        </div>
    );
}

const LoaderScreen = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const minLoadingDuration = 2000; // 1 second
    const [loadingOpacity, setLoadingOpacity] = useState(1);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), minLoadingDuration);
        return () => clearTimeout(timer); // Clear the timer if the component unmounts
        document.body.style.overflow = loading ? 'hidden' : 'auto';
    }, [loading]);


    return (
        <div style={{position: 'relative'}}>
            {loading && (

                <div style={{position: 'fixed', top: 0, bottom: 0, width: '100%', height: '100%', transition: 'opacity 0.9s', opacity: loadingOpacity}}>
                    <LoadingGif />
                </div>
            )}
            <div style={{transition: 'opacity 0.9s', opacity: loading ? 0 : 1}}>
                {children}
            </div>
        </div>
    );
};

export default LoaderScreen;
