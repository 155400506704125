import React from "react";
import Lottie from "lottie-react";


function LottieGif ( {gifpath, height, width} ) {
    const lottieStyle = {
        height: height || "100%",  // Default height is 100%
        width: width || "100%"     // Default width is 100%
    };

    return (
        <Lottie animationData={gifpath} loop={true}
        style={lottieStyle}/>
    );
}

export default LottieGif;